@mixin paper ($elevation, $radius, $shaped-radius)
  +radius($radius)

  &:not(.v-sheet--outlined)
    +elevation($elevation)

  @if ($shaped-radius)
    &.v-sheet--shaped
      +radius($shaped-radius)


@mixin sheet ($component, $elevation, $radius, $shaped-radius)
  .v-sheet.#{$component}
    +paper($elevation, $radius, $shaped-radius)
