html
  font-size: $font-size-root
  overflow-x: hidden
  text-rendering: optimizeLegibility
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

html.overflow-y-hidden
  overflow-y: hidden !important

.v-application
  font-family: $body-font-family
  line-height: $line-height-root

  ::-ms-clear,
  ::-ms-reveal
    display: none

// iOS Safari hack to allow click events on body
@supports (-webkit-touch-callout: none)
  body
    cursor: pointer
