@import '../styles.sass'

.v-application
  code, kbd
    border-radius: $code-kbd-border-radius
    font-size: $code-kbd-font-size
    font-weight: $code-kbd-font-weight

  code
    padding: $code-padding

  kbd
    padding: $kbd-padding
    +elevation(2)

+theme(v-application) using ($material)
  code
    background-color: map-deep-get($material, 'code', 'background')
    color: map-deep-get($material, 'code', 'color')

  kbd
    background: map-deep-get($material, 'kbd', 'background')
    color: map-deep-get($material, 'kbd', 'color')
