// Imports
@import './_variables.scss'

// Theme
+theme(v-sheet) using ($material)
  background-color: map-get($material, 'cards')
  border-color: map-get($material, 'cards')
  color: map-deep-get($material, 'text', 'primary')

  &--outlined
    border: $sheet-outlined-border-width solid map-get($material, 'dividers')

// Sheet
.v-sheet
  +paper($sheet-elevation, $sheet-border-radius, $sheet-shaped-border-radius)
